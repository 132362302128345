import { ref } from "vue";

export const usePerformance = () => {
  if (!window.performance) {
    throw new Error("Performance API not supported");
  }

  const result = ref(null);
  const timeTaken = ref(0);

  const measurePerformance = (fn, ...args) => {
    const startTime = Math.round(performance.now());
    result.value = fn(...args);
    const endTime = Math.round(performance.now());
    timeTaken.value = endTime - startTime;
  };

  return {
    result,
    timeTaken,
    measurePerformance
  };
};
