import { render, staticRenderFns } from "./PopupSaveChangesResponse.vue?vue&type=template&id=c8243234&scoped=true&lang=pug"
import script from "./PopupSaveChangesResponse.vue?vue&type=script&lang=ts"
export * from "./PopupSaveChangesResponse.vue?vue&type=script&lang=ts"
import style0 from "./PopupSaveChangesResponse.vue?vue&type=style&index=0&id=c8243234&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8243234",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LsButton: require('/workspace/portal/hosting/components/_base/Button/Button.vue').default,LsButtonWrapper: require('/workspace/portal/hosting/components/_base/Button/ButtonWrapper/ButtonWrapper.vue').default})
