
  import {
    Ref,
    defineComponent,
    reactive,
    ref,
    useContext
  } from "@nuxtjs/composition-api";
  import { email, required } from "vuelidate/lib/validators";
  import { useCurrentUser } from "../../use/useFireAuth";

  export default defineComponent({
    name: "SignInWithEmail",
    layout: "portal",

    props: {
      signInUrl: {
        type: String,
        required: true
      }
    },

    setup(props) {
      const context = useContext();
      const { completeSignInWithEmail } = useCurrentUser(context);

      const form: { email: string | null } = reactive({
        email: null
      });

      const error: Ref<string | undefined> = ref(undefined);

      async function confirmEmail() {
        try {
          if (form.email) {
            await completeSignInWithEmail(form.email, props.signInUrl);
          } else {
            error.value = "Sign in failed. An email must be supplied";
          }
        } catch (signInError: any) {
          error.value = signInError.message;
        }
      }

      return {
        form,
        error,
        confirmEmail
      };
    },

    validations: {
      form: {
        email: {
          required,
          email
        }
      }
    }
  });
