import { ePermissionIds } from "@loadsure/core";
import { iRoute } from "../types/types";

const ClaimsRoutes: iRoute[] = [
  {
    paths: ["/claims/drafts", "/claims/listFilter", "claims/view"],
    permissions: [
      ePermissionIds.VIEW_MY_CLAIMS,
      ePermissionIds.VIEW_COMPANY_CLAIMS,
      ePermissionIds.VIEW_ALL_CLAIMS,
      ePermissionIds.EDIT_MY_CLAIMS,
      ePermissionIds.EDIT_COMPANY_CLAIMS,
      ePermissionIds.EDIT_ALL_CLAIMS,
      ePermissionIds.MANAGE_ALL_CLAIMS
    ],
    allowAccessAll: false
  },
  {
    paths: ["/claims/find"],
    permissions: [ePermissionIds.SUBMIT_CLAIMS],
    allowAccessAll: false
  },
  {
    paths: ["/claims/file"],
    permissions: [
      ePermissionIds.EDIT_MY_CLAIMS,
      ePermissionIds.EDIT_COMPANY_CLAIMS,
      ePermissionIds.EDIT_ALL_CLAIMS,
      ePermissionIds.MANAGE_ALL_CLAIMS
    ],
    allowAccessAll: false
  }
];

export default ClaimsRoutes;
