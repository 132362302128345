import { ePermissionIds } from "@loadsure/core";
import { iRoute } from "../types/types";

export const QuoteOrBuyRoutes: iRoute[] = [
  {
    paths: ["/certificates/buy", "/certificates/selectPolicyHolder"],
    permissions: [
      ePermissionIds.CREATE_QUOTES,
      ePermissionIds.CREATE_CERTIFICATES
    ],
    allowAccessAll: false
  }
];

export const ViewQuoteRoutes: iRoute[] = [
  {
    paths: [
      "/quotes/list",
      "/quotes/list/activity",
      "/quotes/list/created",
      "/quotes/list/received",
      "/quotes/details"
    ],
    permissions: [
      ePermissionIds.VIEW_MY_QUOTES,
      ePermissionIds.VIEW_COMPANY_QUOTES,
      ePermissionIds.VIEW_ALL_QUOTES,
      ePermissionIds.EDIT_MY_QUOTES,
      ePermissionIds.EDIT_COMPANY_QUOTES,
      ePermissionIds.EDIT_ALL_QUOTES
    ],
    allowAccessAll: false
  }
];

export const ViewCertificateRoutes: iRoute[] = [
  {
    paths: [
      "/certificates/list",
      "/certificates/details",
      "/certificates/find"
    ],
    permissions: [
      ePermissionIds.VIEW_MY_CERTIFICATES,
      ePermissionIds.VIEW_COMPANY_CERTIFICATES,
      ePermissionIds.VIEW_ALL_CERTIFICATES,
      ePermissionIds.EDIT_MY_CERTIFICATES,
      ePermissionIds.EDIT_COMPANY_CERTIFICATES,
      ePermissionIds.EDIT_ALL_CERTIFICATES,
      ePermissionIds.EDIT_CERTIFICATES_WITHOUT_RESTRICTION,
      ePermissionIds.CANCEL_MY_CERTIFICATES,
      ePermissionIds.CANCEL_COMPANY_CERTIFICATES,
      ePermissionIds.CANCEL_ALL_CERTIFICATES,
      ePermissionIds.CANCEL_CERTIFICATES_WITHOUT_RESTRICTION
    ],
    allowAccessAll: false
  }
];

export const BulkUploadRoutes: iRoute[] = [
  {
    paths: ["/certificates/bulkUpload"],
    permissions: [ePermissionIds.BULK_UPLOAD],
    allowAccessAll: false
  }
];
