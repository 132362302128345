import { render, staticRenderFns } from "./TheTopNavigation.vue?vue&type=template&id=d8ea4e0a&scoped=true&lang=pug"
import script from "./TheTopNavigation.vue?vue&type=script&lang=ts"
export * from "./TheTopNavigation.vue?vue&type=script&lang=ts"
import style0 from "./TheTopNavigation.vue?vue&type=style&index=0&id=d8ea4e0a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8ea4e0a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LogoPortal: require('/workspace/portal/hosting/components/LogoPortal.vue').default,UserMenu: require('/workspace/portal/hosting/components/UserMenu.vue').default,LsButton: require('/workspace/portal/hosting/components/_base/Button/Button.vue').default,LsButtonWrapper: require('/workspace/portal/hosting/components/_base/Button/ButtonWrapper/ButtonWrapper.vue').default})
