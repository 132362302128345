import { render, staticRenderFns } from "./InternetTradingPolicy.vue?vue&type=template&id=e7a29fa2&scoped=true&lang=pug"
import script from "./InternetTradingPolicy.vue?vue&type=script&lang=ts"
export * from "./InternetTradingPolicy.vue?vue&type=script&lang=ts"
import style0 from "./InternetTradingPolicy.vue?vue&type=style&index=0&id=e7a29fa2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e7a29fa2",
  null
  
)

export default component.exports