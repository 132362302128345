
  import { defineComponent, useContext } from "@nuxtjs/composition-api";
  import { useInternetTradingPolicyInject } from "../use/useInternetTradingPolicy";

  export default defineComponent({
    name: "InternetTradingPolicy",
    props: {
      showPDFButton: {
        type: Boolean,
        default: true
      }
    },
    setup() {
      const context = useContext();
      const { downloadUrl } = useInternetTradingPolicyInject(context);

      return {
        downloadUrl
      };
    }
  });
