
  import { defineComponent } from "@nuxtjs/composition-api";

  export default defineComponent({
    props: {
      dark: Boolean,
      maxWidth: {
        type: Number,
        default: 400
      }
    },
    setup(props) {
      const src = props.dark
        ? "/logos/loadsure/logo-white.png"
        : "/logos/loadsure/logo-black.png";
      const style = `max-width: ${props.maxWidth}px;`;
      return {
        src,
        style
      };
    }
  });
