
  import { defineComponent, useContext } from "@nuxtjs/composition-api";
  import { useInternetTradingPolicyInject } from "../use/useInternetTradingPolicy";

  export default defineComponent({
    name: "InternetTradingPolicyDetailsPopUp",

    setup() {
      const context = useContext();
      const { showITPDetails, closeITPDetails, downloadUrl } =
        useInternetTradingPolicyInject(context);

      return {
        showITPDetails,
        closeITPDetails,
        downloadUrl
      };
    }
  });
