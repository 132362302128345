import { Ref, computed, inject, provide, ref } from "@nuxtjs/composition-api";
import { logger } from "@loadsure/utils";
import { useApi } from "./useApi";
import { useCurrentUser } from "./useFireAuth";

export function useInternetTradingPolicyProvide(
  internetTradingPolicy,
  userPolicies
) {
  const showITP = ref(false);
  const showITPDetails = ref(false);
  const loading = ref(false);

  function viewITPDetails() {
    showITPDetails.value = true;
  }

  function closeITPDetails() {
    showITPDetails.value = false;
  }

  const itpVersion = computed(() => internetTradingPolicy?.value?.version);
  const downloadUrl = computed(() => internetTradingPolicy.value?.url);

  const currentVersionAccepted = ref(false);

  const emailOverride: Ref<string> = ref("");

  provide("emailOverride", emailOverride);
  provide("viewITPDetails", viewITPDetails);
  provide("closeITPDetails", closeITPDetails);
  provide("showITP", showITP);
  provide("showITPDetails", showITPDetails);
  provide("loading", loading);
  provide("downloadUrl", downloadUrl);
  provide("itpVersion", itpVersion);
  provide("userPolicies", userPolicies);
  provide("currentVersionAccepted", currentVersionAccepted);

  return {
    showITP,
    showITPDetails,
    downloadUrl
  };
}

export function useInternetTradingPolicyInject(context) {
  const { getFullUrl } = useApi(context);
  const { $axios } = context;

  const showITP = inject("showITP") as Ref<boolean>;
  const showITPDetails = inject("showITPDetails") as Ref<boolean>;
  const viewITPDetails = inject("viewITPDetails");
  const closeITPDetails = inject("closeITPDetails");
  const loading = inject("loading") as Ref<boolean>;
  const userPolicies = inject("userPolicies") as Ref<any>;
  const downloadUrl = inject("downloadUrl") as Ref<string>;

  const itpVersion = inject("itpVersion") as Ref<string>;
  const currentVersionAccepted = inject(
    "currentVersionAccepted"
  ) as Ref<boolean>;

  const emailOverride = inject("emailOverride") as Ref<string>;
  const { email: emailLoggedIn } = useCurrentUser(context);

  const showITPError = ref(false);

  const email = computed(() => emailOverride.value || emailLoggedIn.value);

  const acceptedUserITP = computed(() => userPolicies?.value?.userITP);

  async function verifyUserAcceptedVersion(otherEmail?: string) {
    if (otherEmail) {
      emailOverride.value = otherEmail;
      const policies = await $axios.$get(getFullUrl("/user/userPolicies"), {
        params: { email: email.value }
      });
      userPolicies.value = policies?.result;
    }
    logger.info("check ITP - userPolicies", userPolicies.value);
    logger.info("check ITP - acceptedUserITP", acceptedUserITP.value);
    logger.info(
      "check ITP - currentVersionAccepted",
      currentVersionAccepted.value
    );
    if (!currentVersionAccepted.value && email.value && itpVersion.value) {
      if (acceptedUserITP.value) {
        showITP.value = !acceptedUserITP.value[itpVersion.value];
        currentVersionAccepted.value = !showITP.value;
      } else {
        showITP.value = true;
      }
    } else {
      showITP.value = false;
      showITPDetails.value = false;
    }
  }

  const acceptITP = async () => {
    if (itpVersion.value) {
      try {
        loading.value = true;
        const doc = {
          acceptedVersions: {
            [`${itpVersion.value}`]: {
              date: new Date().toISOString()
            }
          }
        };
        const postData = {
          policyType: "internetTradingPolicy",
          doc
        };

        await $axios.$post(getFullUrl("/user/userPolicies"), {
          ...postData,
          email: email.value
        });

        currentVersionAccepted.value = true;
        showITP.value = false;
      } catch (error) {
        logger.error(error);
      } finally {
        loading.value = false;
      }
    }
  };

  const closeITPErrorDetails = () => {
    showITPError.value = false;
  };

  return {
    showITP,
    showITPDetails,
    acceptITP,
    viewITPDetails,
    closeITPDetails,
    loading,
    downloadUrl,
    itpVersion,
    verifyUserAcceptedVersion,
    showITPError,
    closeITPErrorDetails,
    emailOverride
  };
}
