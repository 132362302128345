
  import {
    computed,
    defineComponent,
    onMounted,
    ref
  } from "@nuxtjs/composition-api";

  export default defineComponent({
    name: "Loading",
    layout: "portal",
    setup() {
      const timer = ref<number>(0);
      const number = computed<string>(() => timer.value.toFixed(1));

      const animate = async () => {
        for (let i = 0; i < 20; i++) {
          // eslint-disable-next-line no-await-in-loop
          await new Promise((r) => setTimeout(r, 60));
          timer.value = Math.round((timer.value + 0.1) * 10) / 10;
        }
      };

      onMounted(animate);

      return { number };
    }
  });
