
  import {
    PropType,
    computed,
    defineComponent,
    onMounted
  } from "@nuxtjs/composition-api";
  import CONSTANTS from "../../../use/useConstants";

  export enum eAlertType {
    WARNING = "warning",
    ERROR = "error",
    SUCCESS = "success"
  }

  export default defineComponent({
    name: "Alert",
    props: {
      type: {
        type: String as PropType<eAlertType>,
        default: "error"
      },
      text: {
        type: String,
        required: true,
        default: ""
      },
      iconColor: {
        type: String,
        required: false,
        default: ""
      },
      // number of seconds alert will take to disappear
      timeout: {
        type: Number,
        default: 5
      },
      dismissible: {
        type: Boolean,
        default: true
      },
      boldText: {
        type: String,
        required: false,
        default: ""
      }
    },
    emits: ["close"],
    setup(props, { emit }) {
      const getAlertTypes = () => {
        const { semantic500, warning500, secondary500 } = CONSTANTS.colors;
        return {
          error: {
            name: "error_outline",
            color: semantic500,
            class: "alert--error"
          },
          warning: {
            name: "warning",
            color: warning500,
            class: "alert--warning"
          },
          success: {
            name: "check_circle_outline",
            color: secondary500,
            class: "alert--success"
          }
        };
      };

      const currentAlertType = computed(() => getAlertTypes()[props.type]);

      const closeAlert = () => {
        emit("close");
      };

      onMounted(() => {
        if (props.dismissible && props.timeout)
          setTimeout(() => closeAlert(), props.timeout * 1000);
      });

      return {
        currentAlertType,
        CONSTANTS,
        closeAlert
      };
    }
  });
