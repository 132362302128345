
  import { defineComponent, ref } from "@nuxtjs/composition-api";

  export default defineComponent({
    name: "AnimatedLogo",
    setup() {
      const isReady = ref<boolean>(false);

      setTimeout(() => {
        isReady.value = true;
      }, 200);

      return { isReady };
    }
  });
